$.ajaxSetup({	
	headers: {
		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	}	
});

$(document).ready(function(){

	(function(d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s); js.id = id;
		js.src = "//connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v2.10&appId=1928259330831734";
		fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));

	var ajaxLocal="http://www.amorcanino.com.mx/";
	//var ajaxLocal="http://dev.historiasperrunas.com/";

	var compania=0;
	var proteccion=0;
	var ensenanza=0;
	var rescate=0;
	var plenitud=0;

	var res1=0;
	var res2=0;
	var res3=0;
	var res4=0;
	var res5=0;
	var res5=0;

	$(".border-box-test .next").on("click", function(){
		 $(this).parent().parent().slideUp();
		 compania, proteccion,ensenanza,rescate,plenitud=0;	 
	});

	$(".border-box-test .siguiente").on("click", function(){

		numero=$(this).data('question');	
		respuesta=$('input[name="'+numero+'"]:checked').data('opt');

		if($('input[name="'+numero+'"]').is(":checked")){

		}else{
			$(this).prev().removeClass('hidden');	
			return false;
		}
		
		$(this).parent().parent().slideUp();

		if(numero=='ask1'){
			res1=respuesta;

		}else if(numero=='ask2'){
			res2=respuesta;

		}else if(numero=='ask3'){
			res3=respuesta;

		}else if(numero=='ask4'){
			res4=respuesta;

		}else if(numero=='ask5'){
			res5=respuesta;

		}else if(numero=='ask6'){
			res6=respuesta;
		}

		

		if(respuesta==1){
			compania+=1;
		}else if(respuesta==2){
			proteccion+=1;
		}else if(respuesta==3){
			ensenanza+=1;
		}else if(respuesta==4){
			rescate+=1;
		}
		else if(respuesta==5){
			plenitud+=1;
		}	 
	});

	$("#final").on("click",function(){

		resultado="";
		if(compania >=proteccion  && compania >=ensenanza  && compania >=rescate  && compania >=plenitud ){
			resultado="compania";
		}

		if(proteccion >=compania  && proteccion >=ensenanza && proteccion >=rescate && proteccion >=plenitud ){
			resultado="proteccion";
		}
		if(ensenanza >=compania  && ensenanza >=proteccion && ensenanza >=rescate && ensenanza >=plenitud ){	
			resultado="ensenanza";
		}

		if(rescate >=compania  && rescate >=proteccion && rescate >=ensenanza && rescate >=plenitud ){
			resultado="rescate";
		}

		if(plenitud >=compania  && plenitud >=proteccion && plenitud >=ensenanza && plenitud >=rescate ){
			
			resultado="plenitud";
			
		}
		$.post(ajaxLocal+'save-test',{res1,res2,res3,res4,res5,res6,resultado})
		.done(function(contenido){

		});

		setTimeout(function(){
			$("#descubrir").slideUp();
			$("."+resultado).slideDown();
		}, 3000);
	});

	$("#finalColima").on("click",function(){

		resultado="";
		if(compania >=proteccion  && compania >=ensenanza  && compania >=rescate  && compania >=plenitud ){
			resultado="compania";
		}

		if(proteccion >=compania  && proteccion >=ensenanza && proteccion >=rescate && proteccion >=plenitud ){
			resultado="proteccion";
		}
		if(ensenanza >=compania  && ensenanza >=proteccion && ensenanza >=rescate && ensenanza >=plenitud ){	
			resultado="ensenanza";
		}

		if(rescate >=compania  && rescate >=proteccion && rescate >=ensenanza && rescate >=plenitud ){
			resultado="rescate";
		}

		if(plenitud >=compania  && plenitud >=proteccion && plenitud >=ensenanza && plenitud >=rescate ){
			
			resultado="plenitud";
			
		}
		estado="Colima";

		$.post(ajaxLocal+'save-test-colima',{res1,res2,res3,res4,res5,res6,resultado,estado})
		.done(function(contenido){

		});

		setTimeout(function(){
			$("#descubrir").slideUp();
			$("."+resultado).slideDown();
		}, 3000);
	});
	
	$(".border-box-test .check").on("click", function(){

		if($(this).prev().prop('checked')){
			//$(this).prev().removeAttr("checked");       
		}
		else{       
			$(this).prev().prop('checked', true);
		} 
	});

	$(".border-box-test p").on("click", function(){

		if($(this).prev().prev().prop('checked')){
			//$(this).prev().removeAttr("checked");       
		}
		else{       
			$(this).prev().prev().prop('checked', true);
		} 
	});
	
	
})